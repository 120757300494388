<template>
  <div class="page">
    <section class="header">
      <div class="back">
        <Back />
      </div>
      <div class="title">
        <span>
          Manage NFTs
        </span>
      </div>
      <div class="avatar">
        <vs-avatar circle >
          <img :src="avatar" />
        </vs-avatar>
      </div>
    </section>
    <section class="cards">
      <Card color="purple" title="Vitrine">
        <template #accessibility>
          <vs-tooltip color="#7553FF">
            <i class="bx bx-info-circle" style="color:rgba(0,0,0,0.2)" />
            <template #tooltip>
              Drag to reorder
            </template>
          </vs-tooltip>
        </template>
        <template #content>
          <draggable :list="listShown" group="nfts" class="draggable">
            <NFTItem
              size="70"
            />
          </draggable>
        </template>
        <template #footer-button>
          <div @click="hideAll">
            <white-button textColor="purple" size="mini">
              Hide All
            </white-button>
          </div>
        </template>
      </Card>
      <Card color="purple" title="No-show collections">
        <template #accessibility>
          <vs-tooltip color="#7553FF">
            <i class="bx bx-info-circle" style="color:rgba(0,0,0,0.2)" />
            <template #tooltip>
              Drag NFTs you'd like to hide here
            </template>
          </vs-tooltip>
        </template>
        <template #content>
          <draggable :list="listHidden" group="nfts" class="draggable">
            <NFTItem
              size="70"
            />
          </draggable>
        </template>
        <template #footer-button>
          <div @click="showAll">
            <white-button textColor="purple" size="mini">
              Show All
            </white-button>
          </div>
        </template>
      </Card>
    </section>
    <section class="nav">
      <vs-button size="large" class="button-nav button-nav-discard button-shadow-primary">
        Discard
      </vs-button>
      <vs-button size="large" class="button-nav button-nav-save button-shadow-primary">
        Save
      </vs-button>
    </section>
  </div>
</template>

<script>
import Back from '../../components/Buttons/Back'
import Card from '../../components/Card'
import WhiteButton from '../../components/Buttons/WhiteButton'
import NFTItem from '../../components/NFT/NFTItem'

import draggable from 'vuedraggable'

import RSS3 from '@/common/rss3'

export default {
  name: 'ManageNFTs',
  components: { NFTItem, WhiteButton, Card, Back, draggable },
  data () {
    return {
      avatar: '',
      listShown: [],
      listHidden: [],
      rss3: null
    }
  },
  async mounted () {
    this.rss3 = await RSS3.get()
    const profile = await this.rss3.profile.get()
    this.avatar = profile?.avatar?.[0] || ''
  },
  methods: {
    hideAll () {
      this.listHidden.push(...this.listShown)
      this.listShown.splice(0, this.listShown.length)
    },
    showAll () {
      this.listShown.push(...this.listHidden)
      this.listHidden.splice(0, this.listHidden.length)
    }
  }
}
</script>

<style lang="less" scoped>
.page {

  .header {
    position: fixed;
    padding: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      color: @color-primary;
    }
  }

  .cards {

    margin: 5em 1em 3em 1em;

    > * {
      margin-bottom: 1rem;
    }

    .tips {
      align-self: center;
      color: @color-purple;
      font-weight: 100;
    }

  }

  .nav {
    text-align: center;
    position: fixed;
    bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    background-color: #FFF;
    z-index: 1;
    > .button-nav {
      width: 40%;
      display: inline-block;
    }
  }

  .draggable {
    width: 100%;
    min-height: 80px;
    > * {
      margin: .25rem;
      display: inline-flex;
    }
  }

  .button-nav-discard {
    color: @color-primary;
    background-color: #FFF;
  }
}
</style>
