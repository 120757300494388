<template>
<div class="white-button" :style="style">
  <vs-button
    :class="{
      'primary': $props.textColor === 'primary',
      'purple': $props.textColor === 'purple',
      'green': $props.textColor === 'green'
    }"
    :icon="$props.isIcon"
    :size="$props.size"
    >
  <slot/>
  </vs-button>
</div>
</template>

<script>
export default {
  name: 'WhiteButton',

  props: {
    size: String,
    textColor: String,
    width: String
  },
  computed: {
    style () {
      return `width: ${this.width}`
    }
  }
}
</script>

<style lang="less" scoped>
.white-button {
  > * {
    width: 100%;
  }
  .vs-button{
    background-color: white;
  }
.primary{
  color: #0072FF;
  box-shadow: 0px 9px 35px -7px rgba(55, 155, 255, 0.56);
}
.purple {
  color: #7553FF;
  box-shadow: 0px 4px 16px -6px rgba(117, 83, 255, 0.78);
}
.green {
  color: #40D22F;
  box-shadow: 0px 4px 16px -6px rgba(19, 189, 0, 0.78);
}
}
</style>
