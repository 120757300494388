<template>
  <div class="main">
    <div class="header">
      <back />
      <div class="section-title">Accounts</div>
      <div class="avatar-container"><vs-avatar circle size="34"><img :src="rss3Profile.avatar"/></vs-avatar></div>
    </div>
    <div class="account-list">
      <div class="account-wrapper" v-for="(item,index) in accountList" :key="index">
        <account-item size="70" :chain="item.chain" />
        <span class="address">{{item.address.slice(0,6)}}...{{item.address.slice(-4)}}</span>
        <vs-button icon class="button-shadow-green" color="#40D22F" @click="copyAddress(item.address)"><i class="bx bxs-copy" /></vs-button>
        <vs-button icon class="button-shadow-green" color="#40D22F" @click="jumpToAddress(item.address)"><i class="bx bx-link-external" /></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '@/components/Buttons/Back.vue'
import AccountItem from '@/components/AccountItem.vue'
import axios from 'axios'
export default {
  components: { Back, AccountItem },
  name: 'Accounts',
  props: {
    avatar: String,
    accounts: Array
  },
  async mounted () {
    const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/asset-profile/${this.$route.params.address}`)
    console.log(res.data)

    if (res.data) {
      this.rss3Profile.avatar = res.data.rss3File.profile?.avatar?.[0]

      this.accountList.push({
        chain: 'Ethereum',
        address: this.$route.params.address
      })

      if (res.data.rss3File.accounts) {
        res.data.rss3File.accounts.forEach(account => {
          this.accountList.push({
            chain: account.platform,
            address: account.identity
          })
        })
      }
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      rss3Profile: { avatar: '' },
      accountList: []
    }
  },
  methods: {
    copyAddress (address) {
      navigator.clipboard.writeText(address)
    },
    jumpToAddress (address) {
      window.open(`https://rss3.page/${address}`)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  background-color: #F6FFF5;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem 1rem;
  .vs-button {
    width: 100%;
  }
  .button-text {
    margin-right: 8px;
  }
  > * {
    margin-bottom: 16px;
  }
  .header {
    display: grid;
    grid-template-columns: 34px auto 34px;
    width: 100%;
    align-items: center;
  }
  .section-title {
    width: 100%;
    text-align: center;
    font-family: SFCompactRounded;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #13BD00;
  }
  .avatar-container {
    width: 34px;
    height: 34px;
  }
  .account-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    .account-wrapper{
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      width: 100%;
      .address {
      font-weight: 600;
      font-size: 1.5rem;
      }
    }

  }
}
</style>
