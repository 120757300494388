var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"image-holder",class:{
    'round': _vm.$props.type === 'round',
    'square': _vm.$props.type === 'square',
    'shadow': _vm.$props.shadow,
    'success': _vm.$props.color === 'success',
    'secondary': _vm.$props.color === 'secondary',
  },style:({
    width: _vm.size,
    height: _vm.size,
  })},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.url || _vm.$props.src),expression:"url || $props.src"}],staticClass:"image",attrs:{"src":_vm.url || _vm.$props.src}}),(_vm.$props.addButton)?_c('span',{staticClass:"plus",class:{
    'fill': _vm.url || _vm.$props.src
    }},[_c('i',{staticClass:"bx bx-plus"})]):_vm._e(),_c('input',{staticClass:"hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.preview}})])}
var staticRenderFns = []

export { render, staticRenderFns }