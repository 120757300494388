<template>
  <div class="main">
    <div class="header">
      <back></back>
      <div class="section-title">Followers</div>
      <div class="avatar-container"><vs-avatar circle size="34"><img :src="rss3Profile.avatar"/></vs-avatar></div>
    </div>
    <div class="follow-list">
      <follower-card v-for="(item,index) in rss3Relations.followers" :key="index" :avatar="item.avatar" :username="item.username" :address="item.address"></follower-card>
    </div>
  </div>
</template>

<script>
import Back from '../../components/Buttons/Back.vue'
import FollowerCard from '../../components/FollowerCard.vue'
import RSS3 from '@/common/rss3'
export default {
  components: { Back, FollowerCard },
  name: 'Followers',
  props: {
    avatar: String,
    followers: Array
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      rss3Profile: { avatar: '' },
      rss3Relations: {
        followers: []
      }

    }
  },
  async mounted () {
    const rss3 = await RSS3.visitor()
    const profile = await rss3.profile.get(this.$route.params.address)
    this.rss3Profile.avatar = profile?.avatar?.[0] || ''
    const followersList = await rss3.backlinks.get(this.$route.params.address, 'following')
    console.log(followersList)
    if (rss3 && followersList) {
      for (const fo of followersList) {
        const profile = await rss3.profile.get(fo)
        this.rss3Relations.followers.push({
          avatar:
            profile.avatar?.[0] || '',
          username: profile.name || '',
          address: fo
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem 1rem;
  .vs-button {
    width: 100%;
  }
  .button-text {
    margin-right: 8px;
  }
  > * {
    margin-bottom: 16px;
  }
  .header {
    display: grid;
    grid-template-columns: 34px auto 34px;
    width: 100%;
    align-items: center;
  }
  .section-title {
    width: 100%;
    text-align: center;
    font-family: SFCompactRounded;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #0072FF;
  }
  .avatar-container {
    width: 34px;
    height: 34px;
  }
    .follow-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}
</style>
