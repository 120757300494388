<template>
  <div class="onboarding">
    <div>
      <section>
        <h1 class="hello">
          Hello
        </h1>
      </section>
      <section>
        <div class="button">
          <vs-button size="xl" gradient @click="connect">
            Connect a wallet
          </vs-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import RSS3 from '@/common/rss3'

export default {
  name: 'Onboarding',
  methods: {
    async connect () {
      //  Get RSS3
      const rss3 = await RSS3.get()
      console.log(rss3)

      let profile

      try {
        profile = await rss3.profile.get()
        console.log(profile)
      } catch (e) {
        console.error(e)
      }

      if (profile) {
        this.$gtag.config(rss3.account.address)
        this.$gtag.event('login', { userid: rss3.account.address, event_label: rss3.account.address })
        await this.$router.push('/public')
      } else {
        this.$gtag.config(rss3.account.address)
        this.$gtag.event('sign_up', { userid: rss3.account.address, event_label: rss3.account.address })
        await this.$router.push('/settings')
      }

      // const sign = await Web3.sign('Demo data')
      // console.log(sign)
    }
  }

}
</script>

<style lang="less" scoped>
.onboarding {

  height: 100%;
  text-align: center;

  background-image: url("../assets/images/rss3-bg.png");
  background-size: cover;
  background-attachment: fixed;

  > div {
    height: 100%;
    max-width: 50rem;
    margin: 0 auto;

    > section {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hello {
      margin: 0;
      font-weight: 700;
      font-size: 6rem;
      color: #0072ff;
      text-shadow: 0 9px 35px #B7D7FF;
    }

    .button {
      width: 75%;
      > * {
        width: 100%;
        font-size: 1.5rem;
        box-shadow: 0px 9px 35px -7px #0072FF;
      }
    }

  }
}
</style>
