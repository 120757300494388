<template>

<div class="info-container item-shadow-purple">
<div class="info-header">{{$props.detail.name}} #{{$props.detail.token_id}}</div>
<div class="section">
  <div class="section-title">Description</div>
  <div class="section-content">{{$props.detail.description}}</div>
</div>
<div class="section">
  <div class="section-title">Properties</div>
  <div class="section-content">
    <div class="trait-list">
    <trait v-for="(item,index) in $props.detail.traits" :key="index" :traitType="item.trait_type" :traitValue="item.value"></trait>
    </div>
  </div>
</div>
<div class="section">
  <div class="section-title">About {{$props.detail.collection.name}}</div>
  <div class="section-content">{{$props.detail.collection.description}}</div>
</div>
</div>

</template>

<script>
import Trait from './Trait.vue'
export default {
  components: { Trait },
  name: 'NFTDetail',
  props: {
    detail: Object
  },
  computed: {
    style () {
      return {
        width: this.windowWidth - 32 + 'px',
        height: this.windowWidth - 32 + 'px'
      }
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth
    }
  }
}
</script>

<style lang="less" scoped>

  .img-container{
    border-radius: 20px;
    border: 4px solid #FFFFFF;
    img {
      object-fit: contain;
    }
  }
  .info-container {
    background-color: #fff;
    border-radius: 20px;
    font-family: SFCompactRounded;
    color: #000;
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    .info-header {
      font-size: 20px;
      font-weight: 600;
      word-break: break-all;
    }
    .section-title{
      text-transform: capitalize;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 8px;
    }
    .section-content{
      font-size: 14px;
      line-height: 1.5;
      word-break: break-word;
    }
    .trait-list{
      display: flex;
      flex-direction:row;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
</style>
