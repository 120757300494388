<template>
  <div class="page">
    <section class="header">
      <div class="back">
        <Back />
      </div>
      <div class="title">
        <span>
          Manage Accounts
        </span>
      </div>
      <div class="avatar">
        <vs-avatar circle >
          <img :src="avatar" />
        </vs-avatar>
      </div>
    </section>
    <section class="cards">
      <Card title="Default ETH Account" title-color="green">
        <template #content>
          <vs-tooltip color="#40D22F" right>
            <AccountItem :size="walletIconSize" chain="Ethereum" />
            <template #tooltip>
              {{address.slice(0,6)}}...{{address.slice(-4)}}
            </template>
          </vs-tooltip>
        </template>
      </Card>
      <Card :color="isNotShowMore ? 'green' : ''" title="Additional Accounts" title-color="green">
        <template #accessibility>
          <vs-tooltip color="#40D22F">
            <i class="bx bx-info-circle" style="color:rgba(0,0,0,0.2)" />
            <template #tooltip>
              Drag to reorder
            </template>
          </vs-tooltip>
        </template>
        <template #button>
          <vs-button
            icon
            size="small"
            class="button-shadow-green-sm"
            :class="{
              'button-card-add': isNotShowMore,
              'button-card-close': !isNotShowMore
            }"
            @click="isNotShowMore = !isNotShowMore"
          >
            <i v-if="isNotShowMore" class="bx bx-plus" />
            <i v-else class="bx bx-x" />
          </vs-button>
        </template>
        <template #content>
          <draggable
            v-if="isNotShowMore"
            :list="listShown"
            group="accounts"
            class="draggable"
          >

            <vs-tooltip
              v-for="(e, i) in listShown"
              :key="i"
              color="#40D22F"
            >
              <AccountItem
                :size="walletIconSize"
                :chain="e.platform"
              />
              <template #tooltip>
                {{e.identity.slice(0,6)}}...{{e.identity.slice(-4)}}
              </template>
            </vs-tooltip>

          </draggable>
          <div v-else class="draggable">
            <vs-tooltip
              v-for="(e, i) in listMoreAccounts"
              :key="e"
              color="#40D22F"
            >
              <span
                @click="enableAccount(e, i)"
              >
              <AccountItem
                :size="walletIconSize"
                :chain="e"
              />
            </span>
              <template #tooltip>
                {{ e }}
              </template>
            </vs-tooltip>

          </div>
        </template>
        <template #footer-button>
          <div @click="hideAll">
            <white-button textColor="green" size="mini">
              Hide All
            </white-button>
          </div>
        </template>
      </Card>
      <Card color="green" title="No-show Accounts">
        <template #accessibility>
          <vs-tooltip color="#40D22F">
            <i class="bx bx-info-circle" style="color:rgba(0,0,0,0.2)" />
            <template #tooltip>
              Drag accounts you’d like to hide here
            </template>
          </vs-tooltip>
        </template>
        <template #content>
          <draggable :list="listHidden" group="accounts" class="draggable">
            <vs-tooltip
              v-for="(e, i) in listHidden"
              :key="i"
              color="#40D22F"
            >
              <AccountItem
                :size="walletIconSize"
                :chain="e.platform"
              />
              <template #tooltip>
                {{e.identity.slice(0,6)}}...{{e.identity.slice(-4)}}
              </template>
            </vs-tooltip>
          </draggable>
        </template>
        <template #footer-button>
          <div @click="showAll">
            <white-button textColor="green" size="mini">
              Show All
            </white-button>
          </div>
        </template>
      </Card>
    </section>
    <section class="nav">
      <vs-button size="large" class="button-nav button-nav-discard button-shadow-primary" @click="discard">
        Discard
      </vs-button>
      <vs-button size="large" class="button-nav button-nav-save button-shadow-primary" @click="prepareToSign">
        Save
      </vs-button>
    </section>
    <vs-dialog width="550px" not-center v-model="isShowingSignNotice">
      <template #header>
        <h4 class="not-margin">
          Ready to <b>Sign</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          It's time to sign this RSS3 file.
        </p>
        <p>
          You may need to prepare your wallet for the sign method.
        </p>
      </div>
      <template #footer>
        <div class="con-footer">
          <vs-button @click="callSign" transparent>
            Continue
          </vs-button>
          <vs-button @click="cancelSign" dark transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import Back from '../../components/Buttons/Back'
import Card from '../../components/Card'
import WhiteButton from '../../components/Buttons/WhiteButton'

import draggable from 'vuedraggable'
import AccountItem from '../../components/AccountItem'

import RSS3 from '@/common/rss3'

import Web3 from 'web3'
import axios from 'axios'

export default {
  name: 'ManageAccounts',
  components: { AccountItem, WhiteButton, Card, Back, draggable },
  data () {
    return {
      avatar: '',
      address: '',
      walletIconSize: '70',
      isNotShowMore: true,
      listShown: [],
      listMoreAccounts: ['Ethereum', 'BSC', 'Ronin'],
      listHidden: [],
      rss3: null,
      isShowingSignNotice: false,
      isDragging: false
    }
  },
  async mounted () {
    this.rss3 = await RSS3.get()
    this.address = this.rss3.account.address
    const profile = await this.rss3.profile.get()
    this.avatar = profile?.avatar?.[0] || ''

    const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/${this.rss3.account.address}`)
    console.log(res.data)

    if (res.data) {
      if (res.data.accounts) {
        res.data.accounts.forEach(account => {
          this.listShown.push(account)
        })
      }
    }
  },
  methods: {
    async enableAccount (e, i) {
      let account
      switch (this.listMoreAccounts[i]) {
        case 'Ethereum':
          account = await this.addEthereumAccount('Ethereum')
          break
        case 'BSC':
          account = await this.addEthereumAccount('BSC')
          break
        case 'Ronin':
          account = await this.addEthereumAccount('Ronin')
          break
      }
      this.listShown.push(account)
      this.isNotShowMore = true
    },
    hideAll () {
      this.listHidden.push(...this.listShown)
      this.listShown.splice(0, this.listShown.length)
    },
    showAll () {
      this.listShown.push(...this.listHidden)
      this.listHidden.splice(0, this.listHidden.length)
    },
    async addEthereumAccount (platform) {
      const metaMaskWeb3 = new Web3(window.ethereum)
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      const address = metaMaskWeb3.utils.toChecksumAddress(accounts[0])
      const newAddress = {
        platform: platform,
        identity: address
      }
      newAddress.signature = await metaMaskWeb3.eth.personal.sign(
        this.rss3.accounts.getSigMessage(newAddress),
        address,
        ''
      )
      await this.rss3.accounts.post(newAddress)
      return newAddress
    },
    async addBSCAccount (platform) {
      const address = (await window.BinanceChain.request({ method: 'eth_accounts' }))[0]
      const newAddress = {
        platform: platform,
        identity: address
      }
      newAddress.signature = await window.BinanceChain.request({
        method: 'eth_sign',
        params: [address, this.rss3.accounts.getSigMessage(newAddress)]
      })
      await this.rss3.accounts.post(newAddress)
      return newAddress
    },
    async removeHiddenAccounts () {
      for (const item of this.listHidden) {
        await this.rss3.accounts.delete(item)
      }
    },
    async prepareToSign () {
      await this.removeHiddenAccounts()
      this.isShowingSignNotice = true
    },
    cancelSign () {
      this.$gtag.event('cancelSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      this.isShowingSignNotice = false
    },
    async callSign () {
      this.$gtag.event('callSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      await this.rss3.files.sync()
      this.isShowingSignNotice = false
      await this.$router.push('/public')
    },
    discard () {
      window.history.back()
    }
  }
}
</script>

<style lang="less" scoped>
.page {

  .header {
    position: fixed;
    padding: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
      color: @color-primary;
    }
  }

  .cards {

    margin: 5em 1em 3em 1em;

    > * {
      margin-bottom: 1rem;
    }

    .tips {
      align-self: center;
      color: @color-green;
      font-weight: 100;
    }

  }

  .nav {
    text-align: center;
    position: fixed;
    bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    background-color: #FFF;
    z-index: 1;
    > .button-nav {
      width: 40%;
      display: inline-block;
    }
  }

  .draggable {
    width: 100%;
    min-height: 80px;
    text-align: center;
    > * {
      display: inline-flex;
      margin: .25rem;
    }
  }

  .button-nav-discard {
    color: @color-primary;
    background-color: #FFF;
  }

  .button-card-hide {
    color: @color-green;
    background-color: #FFF;
  }

  .button-card-add {
    background-color: @color-green;
  }

  .button-card-close {
    color: @color-green;
    background-color: @bg-green;
  }
}
</style>
