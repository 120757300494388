import Vue from 'vue'
import VueRouter from 'vue-router'

import Onboarding from '@/views/Onboarding.vue'
import Settings from '@/views/Settings.vue'
import ManageAccounts from '@/views/Manage/Accounts.vue'
import ManageNFTs from '@/views/Manage/NFTs.vue'
import TestingPage from '@/views/TestingPage.vue'
import Home from '@/views/PublicPage/Home.vue'
import Followers from '@/views/PublicPage/Followers.vue'
import Followings from '@/views/PublicPage/Followings.vue'
import Accounts from '@/views/PublicPage/Accounts.vue'
import NFTs from '@/views/PublicPage/NFTs.vue'
import SingleNFT from '@/views/PublicPage/SingleNFT.vue'
import '@/styles/index.less'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Onboarding',
    component: Onboarding
  }, {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/manage/account',
    component: ManageAccounts
  },
  {
    path: '/manage/nft',
    component: ManageNFTs
  },
  {
    path: '/test',
    name: 'TestingPage',
    component: TestingPage
  },
  {
    path: '/public',
    name: 'PublicPage',
    component: Home
  },
  {
    path: '/:address',
    name: 'Public',
    component: Home
  },
  {
    path: '/:address/followers',
    name: 'Followers',
    component: Followers
  },
  {
    path: '/:address/followings',
    name: 'Followings',
    component: Followings
  },
  {
    path: '/:address/accounts',
    name: 'Accounts',
    component: Accounts
  },
  {
    path: '/:address/nfts',
    name: 'NFTs',
    component: NFTs
  },
  {
    path: '/:address/singlenft/:aid/:id',
    name: 'SingleNFT',
    component: SingleNFT
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
