<template>
  <div class="setup">
    <section class="title">
    <span>
      Settings
    </span>
    </section>
    <section class="avatar">
      <ImgHolder
        size="100px"
        type="round"
        color="secondary"
        :add-button="avatarAddButton"
        :src="profile.avatar"
        ref="avatar"
      />
    </section>
    <section class="profile">
      <Input
        placeholder="Username"
        :width="componentWidth+'px'"
        v-model="profile.username"
      />
      <Textarea
        placeholder="Bio"
        :width="componentWidth+'px'"
        v-model="profile.bio"
      />
    </section>
    <section class="nav">
      <vs-button
        size="large"
        class="button-nav button-nav-back button-shadow-primary"
        @click="back"
      >
        Back
      </vs-button>
      <vs-button
        size="large"
        class="button-nav button-nav-next button-shadow-primary"
        ref="saveButton"
        @click="saveProfile"
      >
        Done
      </vs-button>
    </section>
    <vs-dialog width="550px" not-center v-model="isShowingSignNotice">
      <template #header>
        <h4 class="not-margin">
          Ready to <b>Sign</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          It's time to sign this RSS3 file.
        </p>
        <p>
          You may need to prepare your wallet for the sign method.
        </p>
      </div>
      <template #footer>
        <div class="con-footer">
          <vs-button @click="callSign" transparent>
            Continue
          </vs-button>
          <vs-button @click="cancelSign" dark transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import ImgHolder from '../components/ImgHolder.vue'
import Input from '../components/Input.vue'
import Textarea from '../components/Textarea.vue'
import RSS3 from '@/common/rss3'

export default {
  name: 'Settings',
  components: {
    ImgHolder,
    Input,
    Textarea
  },
  data () {
    let rss3

    return {
      avatarAddButton: true,
      profile: {
        avatar: '',
        username: '',
        bio: ''
      },
      componentWidth: window.innerWidth - 32,
      accountIconSize: '70',
      rss3,
      saving: null,
      isShowingSignNotice: false,
      isShowingAddAccount: false,
      accountList: []
    }
  },
  async mounted () {
    this.rss3 = await RSS3.get()
    if (!this.loadEdited()) {
      const profile = await this.rss3.profile.get()
      console.log(profile)
      this.profile.avatar = profile?.avatar?.[0] || ''
      this.profile.username = profile?.name || ''
      this.profile.bio = profile?.bio || ''
    }
  },
  methods: {
    toManageNFT () {
      this.saveEdited()
      this.$router.push('/manage/nft')
    },
    back () {
      this.clearEdited()
      window.history.back()
    },
    async saveProfile () {
      if (!this.rss3) {
        this.rss3 = await RSS3.get()
      }

      this.saving = this.$vs.loading({
        target: this.$refs.saveButton,
        scale: '0.6',
        background: 'primary',
        opacity: 1,
        color: '#fff'
      })

      const avatarUrl = await (this.$refs.avatar).upload()

      const profile = {
        name: this.profile.username,
        bio: this.profile.bio
      }
      if (avatarUrl) {
        profile.avatar = [avatarUrl]
      }

      await this.rss3.profile.patch(profile)

      this.prepareToSign()
      this.saving.close()
    },
    prepareToSign () {
      this.isShowingSignNotice = true
    },
    async callSign () {
      this.$gtag.event('callSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      await this.rss3.files.sync()

      this.clearEdited()
      await this.$router.push('/public')
    },
    cancelSign () {
      this.$gtag.event('cancelSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      this.isShowingSignNotice = false
    },
    saveEdited () {
      localStorage.setItem('profile', JSON.stringify(this.profile))
    },
    loadEdited () {
      if (localStorage.getItem('profile')) {
        this.profile = JSON.parse(localStorage.getItem('profile'))
        return true
      } else {
        return false
      }
    },
    clearEdited () {
      localStorage.removeItem('profile')
    }
  }
}
</script>

<style lang="less" scoped>
.setup {
  height: 100%;
  text-align: center;
  overflow: auto;
  padding-bottom: 3rem;

  > section {
    margin-top: 1rem;
  }

  .title {
    font-weight: 700;
    font-size: 3.5rem;
    color: @color-primary;

    margin-top: 2.25rem;

    > h1 {
      margin: 0;
    }
  }

  .avatar {
    margin-top: 1.125rem;

    > * {
      margin: auto;
    }
  }

  .profile {
    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .cards {

    margin-left: 1rem;
    margin-right: 1rem;

    > * {
      margin-bottom: 1rem;
    }

    .tips {
      display: block;
      margin: auto;
      font-weight: 100;
    }

  }

  .button-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .nav {
    position: fixed;
    bottom: 0;
    padding-top: .5rem;
    padding-bottom: .5rem;
    width: 100%;
    background-color: #FFF;
    z-index: 1;

    > .button-nav {
      width: 40%;
      display: inline-block;
    }
  }

  .con-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

  }

  .button-nfts-edit {
    background-color: @color-purple;
  }

  .button-contents-edit {
    background-color: @color-blue;
  }

  .tips-contents {
    color: @color-blue;
  }

  .button-nav-back {
    color: @color-primary;
    background-color: #FFF;
  }
}
</style>
