<template>
  <vs-button icon class="button-shadow-secondary" @click="back">
    <i class="bx bx-chevron-left"></i>
  </vs-button>
</template>

<script>
export default {
  name: 'Back',

  props: {
    size: String,
    textColor: String,
    isIcon: Boolean
  },
  methods: {
    back () {
      window.history.back()
    }
  }
}
</script>

<style lang="less" scoped>
button{
    background-color: white;
    color: #0072FF;
    margin-right: 6px;
}
</style>
