<template>
  <div class="main">
    <div class="header">
      <back />
      <div class="section-title">{{ detail.collection.name }}</div>
      <!-- <div class="avatar-container" /> -->
    <div class="badges">
      <vs-avatar circle class="item-shadow-purple" size="24">
        <img :src="require(`@/assets/images/chains/${detail.chain}.png`)" />
      </vs-avatar>
      <vs-avatar circle class="item-shadow-purple" size="24">
        <img :src="detail.collection.image_url"/>
      </vs-avatar>
    </div>
    </div>

    <NFTItem
      :size="(windowWidth-32).toString()"
      :chain="detail.chain"
      :thumbnail="detail.image_url"
      :showBadge="false"
      :collectionImg="detail.collection.image_url"
    />
    <NFTDetail :detail="detail" />
  </div>
</template>

<script>
import Back from '../../components/Buttons/Back.vue'
import NFTItem from '../../components/NFT/NFTItem.vue'
import NFTDetail from '../../components/NFT/NFTDetail.vue'
import axios from 'axios'

export default {
  components: {
    Back,
    NFTItem,
    NFTDetail
  },
  name: 'Accounts',
  props: {
    avatar: String,
    nft: Object
  },
  async mounted () {
    const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/asset-profile/${this.$route.params.address}`)
    console.log(res.data)

    if (res.data) {
      this.detail = res.data.assets.ethereum[this.$route.params.aid].nft[this.$route.params.id]
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      detail: {}
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  background-color: #F7F5FF;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem 1rem;

  // .vs-button {
  //   width: 100%;
  // }

  .button-text {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 16px;
  }

  .header {
    display: grid;
    grid-template-columns: 50px auto 50px;
    width: 100%;
    align-items: center;
  }

  // .badges {
  //   top: 36px;
  //   right: 20px;
  // }

  .section-title {
    width: 100%;
    text-align: center;
    font-family: SFCompactRounded;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #2D00E0;
  }

  .avatar-container {
    width: 34px;
    height: 34px;
  }

  .badges {
    // position: absolute;
    // z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    // top: 2.5rem;
    // right: 1.5rem;
    gap: 2px;

    > * {
      border: 2px solid #FFFFFF;
    }
  }
}
</style>
