<template>
<div class="wallet-item" :style="{width:$props.size+'px',height:$props.size+'px'}">
  <vs-avatar
    class="item-shadow-purple"
    :size="$props.size"
    >
  <img :src="$props.thumbnail" />
  </vs-avatar>
  <div class="badges" v-if="$props.showBadge">
    <vs-avatar circle class="item-shadow-purple" size="24"><img :src="require(`@/assets/images/chains/${$props.chain}.png`)" /></vs-avatar>
    <vs-avatar circle class="item-shadow-purple" size="24"><img :src="$props.collectionImg" /></vs-avatar>
  </div>
</div>
</template>

<script>
export default {
  name: 'NFTItem',

  props: {
    size: String,
    chain: String,
    thumbnail: String,
    showBadge: Boolean,
    collectionImg: String
  }
}
</script>

<style lang="less" scoped>
.wallet-item {
  .vs-avatar-content {
    border-radius: 20px;
    border: 4px solid #FFFFFF;

  }
  .vs-avatar {
    position: absolute;
    z-index: 0;
  }
  .vs-avatar img {
    color: rgba(0, 0, 0, 0);
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
  .badges {
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    top: 10px;
    right: 10px;
    gap: 2px;
    > * {
      border: 2px solid #FFFFFF;
    }
  }
}
</style>
