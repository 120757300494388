<template>
  <div class="main">
    <!-- <white-button class="button-shadow-secondary" :isIcon="true" size="default" textColor="blue"><i class="bx bx-x"></i></white-button> -->
    <back></back>
    <white-button size="large" textColor="primary" :width="windowWidth-32+'px'"><span class="button-text">Edit Profile</span><i class="bx bx-pencil"></i></white-button>
    <white-button size="large" textColor="primary" :width="windowWidth-32+'px'"><span class="button-text">Following</span><i class="bx bx-check"></i></white-button>
    <Input placeholder="Username" :width="windowWidth-32+'px'" />
    <Textarea placeholder="Bio" :width="windowWidth-32+'px'" />
    <Card color="purple" :width="windowWidth-32+'px'" title="Title">
      <template  #accessibility><i class="bx bx-info-circle" style="color:rgba(0,0,0,0.2)"></i></template>
      <template #button><white-button textColor="purple" size="mini">Hide All</white-button></template>
      <template #content>
        <div class="card-content">
          <div style="height: 106px">some content</div>
          <span class="tips">Here are some tips</span>
        </div>
      </template>
    </Card>

  </div>
</template>

<script>
import Back from '../components/Buttons/Back.vue'
import WhiteButton from '../components/Buttons/WhiteButton.vue'
import Card from '../components/Card.vue'
import Input from '../components/Input.vue'
import Textarea from '../components/Textarea.vue'

export default {
  components: { Back, WhiteButton, Input, Textarea, Card },
  name: 'TestingPage',
  data () {
    return {
      windowWidth: window.innerWidth
    }
  }
}
</script>

<style lang="less" scoped>
.main {

  width: 100%;
  padding: 2rem 1rem;

  .button-text {
    margin-right: 8px;
  }
  > * {
    margin-bottom: 16px;
  }
  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;
    .tips {
      text-align: center;
      color: #7553FF;
      font-weight: 100;
    }
  }

}
</style>
