<template>
  <div class="main">
    <div class="header">
      <back />
      <div class="section-title">NFTs</div>
      <div class="avatar-container">
        <vs-avatar circle size="34">
          <img :src="rss3Profile.avatar"/>
        </vs-avatar>
      </div>
    </div>
    <div class="nft-list">
      <span
        v-for="(item, index) in nftList"
        :key="index"
        @click="toNFTDetail(item.account, item.index)"
      >
        <NFTItem
          :size="((windowWidth-52)/2).toString()"
          :chain="item.nft.chain"
          :thumbnail="item.nft.image_url"
          :showBadge="true"
          :collectionImg="item.nft.collection.image_url"
        />
      </span>
    </div>
  </div>
</template>

<script>
import Back from '../../components/Buttons/Back.vue'
import NFTItem from '../../components/NFT/NFTItem.vue'

import axios from 'axios'

export default {
  components: {
    Back,
    NFTItem
  },
  name: 'Accounts',
  props: {
    avatar: String,
    accounts: Array
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      rss3Profile: {
        avatar: ''
      },
      nftList: []
    }
  },
  async mounted () {
    const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/asset-profile/${this.$route.params.address}`)
    console.log(res.data)

    if (res.data) {
      this.rss3Profile.avatar = res.data.rss3File.profile?.avatar?.[0]

      res.data.assets.ethereum.forEach((item, aid) => {
        item.nft.forEach((nft, i) => {
          this.nftList.push({
            account: aid,
            index: i,
            nft: nft
          })
        })
      })
    }
  },
  methods: {
    toNFTDetail (account, index) {
      this.$router.push(`/${this.$route.params.address}/singlenft/${account}/${index}`)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  background-color: #F7F5FF;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem 1rem;

  .vs-button {
    width: 100%;
  }

  .button-text {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 16px;
  }

  .header {
    display: grid;
    grid-template-columns: 34px auto 34px;
    width: 100%;
    align-items: center;
  }

  .section-title {
    width: 100%;
    text-align: center;
    font-family: SFCompactRounded;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #2D00E0;
  }

  .avatar-container {
    width: 40px;
    height: 40px;
  }

  .nft-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: .6rem;
    position: relative;

    > * {
      position: relative;
      margin-bottom: 10px;
    }

  }
}
</style>
