<template>
  <label
    class="image-holder"
    :class="{
      'round': $props.type === 'round',
      'square': $props.type === 'square',
      'shadow': $props.shadow,
      'success': $props.color === 'success',
      'secondary': $props.color === 'secondary',
    }"
    :style="{
      width: size,
      height: size,
    }"
  >

    <img
      class="image"
      v-show="url || $props.src"
      :src="url || $props.src"
    />

    <span
      class="plus"
      :class="{
      'fill': url || $props.src
      }"
      v-if="$props.addButton"
    >
      <i class='bx bx-plus'></i>
    </span>
    <input type="file" class="hidden" accept="image/*" @change="preview" />

  </label>
</template>

<script>
import ipfs from '@/common/ipfs'

export default {
  name: 'ImgHolder',
  props: {
    size: String,
    type: String,
    color: String,
    src: String,
    addButton: Boolean,
    shadow: Boolean
  },
  data () {
    return {
      url: this.$props.src
    }
  },
  methods: {
    preview (evt) {
      this.file = evt.target?.files?.[0]
      this.url = URL.createObjectURL(this.file)
    },

    async upload () {
      if (this.file) {
        return await ipfs.upload(this.file)
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-holder {

  display: block;
  background-color: #F4F7F8;
  position: relative;

  &.round, &.round > .image {
    border-radius: 50%;
  }

  .image, .plus {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .plus {
    font-size: 1.5rem;
    border-radius: 50%;
  }

  .plus.fill {
    color: white!important;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &.success .plus {
    color: #24E30F;
  }

  &.secondary .plus {
    color: rgba(0, 0, 0, 0.2);;
  }

  &.shadow {

    &.success {
      filter: drop-shadow(-4px 4px 5px rgba(17, 170, 0, 0.12));
    }

  }

  .hidden {
    opacity: 0;
  }

}
</style>
