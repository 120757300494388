<template>
<div class="wallet-item">
  <vs-avatar
    circle
    class="item-shadow-green"
    color="#ffffff"
    :size="$props.size"
    >
  <img :src="require(`@/assets/images/chains/${$props.chain}.png`)" />
  </vs-avatar>
</div>
</template>

<script>
export default {
  name: 'AccountItem',

  props: {
    size: String,
    chain: String
  }
}
</script>

<style lang="less" scoped>
.wallet-item {
  display: inline-block;
.vs-avatar-content {
  background-color: white;
  border: 4px solid #FFFFFF;
  // img {
  //   width: calc(100% - 6px);
  //   height: calc(100% - 6px);
  // }
.vs-avatar {
  background: white;
  padding: 2px;  img {
    max-width: 75%;
    max-height: 75%;
    object-fit: contain;
  }
}
}
}
</style>
