var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:(_vm.style)},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title-wrapper"},[_c('span',{staticClass:"card-title",class:{
          'green-title': _vm.color==='green' || _vm.titleColor === 'green',
          'purple-title': _vm.color==='purple' || _vm.titleColor === 'purple',
          'blue-title': _vm.color==='blue' || _vm.titleColor === 'blue'
        }},[_vm._v(" "+_vm._s(_vm.$props.title)+" ")]),_vm._t("accessibility")],2),_vm._t("button")],2),_vm._t("content"),_c('div',{staticClass:"card-footer",class:{
      'green': _vm.color==='green',
      'purple': _vm.color==='purple',
      'blue': _vm.color==='blue'
    }},[_c('div',{staticClass:"footer-placeholder"}),_c('div',{staticClass:"footer-button"},[_vm._t("footer-button")],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }