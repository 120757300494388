<template>
  <div class="main">
    <Profile
      :avatar="rss3Profile.avatar"
      :username="rss3Profile.username"
      :address="rss3Profile.address"
      :followers="rss3Relations.followers"
      :followings="rss3Relations.followings"
      :bio="rss3Profile.bio"
    />
    <div v-if="isMinePage" class="button-container" @click="toEditProfile">
      <white-button
        size="large"
        textColor="primary"
        :width="windowWidth-32+'px'"
      >
        <span class="button-text">Edit profile</span>
        <i class="bx bx-pencil"/>
      </white-button>
    </div>
    <div v-else class="button-container">
      <vs-button
        v-show="!isFollowing"
        class="button-shadow-primary"
        size="large"
        color="#0072FF"
        :width="windowWidth-32+'px'"
        @click="follow"
      >
        <span class="button-text">Follow</span>
        <i class="bx bx-plus"></i>
      </vs-button>
      <div @click="unfollow">
        <white-button
          v-show="isFollowing"
          size="large"
          textColor="primary"
          :width="windowWidth-32+'px'"
        >
          <span class="button-text">Following</span>
          <i class="bx bx-check"></i>
        </white-button>
      </div>
    </div>
    <div>
      <Card color="green" :width="windowWidth-32+'px'" title="Accounts">
        <template #button>
          <div class="add-button" v-if="isShowingAddAccount">
            <span @click="toManageAccount">
              <vs-button icon size="small" class="button-accounts-edit button-shadow-green-sm">
                <i class="bx bx-plus"/>
              </vs-button>
            </span>
          </div>
        </template>
        <template #content>
          <div class="card-content" @click="toAccountsPage">
            <vs-avatar-group max="5">
              <vs-avatar v-for="(item,index) in accountList" :key="index" circle class="item-shadow-green"
                         :size="(Math.floor(windowWidth-72)/5).toString()">
                <img :src="require('@/assets/images/chains/'+item.chain+'.png')" :width="Math.floor(windowWidth-72)/6"
                     :height="(Math.floor(windowWidth-72)/6).toString()"/>
              </vs-avatar>
            </vs-avatar-group>
          </div>
        </template>
      </Card>
    </div>
    <div @click="toNFTsPage">
      <Card color="purple" :width="windowWidth-32+'px'" title="NFTs">
        <template #content>
          <div class="card-content">
            <vs-avatar-group max="7">
              <vs-avatar v-for="(item,index) in nftList" :key="index" class="item-shadow-purple"
                         :size="(Math.floor(windowWidth-72)/5).toString()">
                <img class="nftitem" :src="item.image_url" :width="Math.floor(windowWidth-72)/6"
                     :height="(Math.floor(windowWidth-72)/6).toString()"/>
              </vs-avatar>
            </vs-avatar-group>
          </div>
        </template>
      </Card>
    </div>
    <Card color="blue" :width="windowWidth-32+'px'" title="Contents">
      <template #content>
        <div class="card-content">
          <div class="tips">Coming soon</div>
        </div>
      </template>
    </Card>
    <vs-dialog width="550px" not-center v-model="isShowingSignNotice">
      <template #header>
        <h4 class="not-margin">
          Ready to <b>Sign</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          It's time to sign this RSS3 file.
        </p>
        <p>
          You may need to prepare your wallet for the sign method.
        </p>
      </div>
      <template #footer>
        <div class="con-footer">
          <vs-button @click="callSign" transparent>
            Continue
          </vs-button>
          <vs-button @click="cancelSign" dark transparent>
            Cancel
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import Profile from '../../components/Profile.vue'

import WhiteButton from '../../components/Buttons/WhiteButton.vue'
import Card from '../../components/Card.vue'

import RSS3 from '@/common/rss3'
import axios from 'axios'

export default {
  components: {
    WhiteButton,
    Card,
    Profile
  },
  name: 'Home',
  async mounted () {
    if (this.$route.params?.address) {
      this.isMinePage = false

      this.rss3 = await RSS3.visitor()
      this.rss3Profile.address = this.$route.params.address
      const profile = await this.rss3.profile.get(this.$route.params.address)

      this.rss3Profile.avatar = profile?.avatar?.[0] || ''
      this.rss3Profile.username = profile?.name || ''
      this.rss3Profile.bio = profile?.bio || ''

      await this.loadFollows()

      const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/asset-profile/${this.$route.params.address}`)
      console.log(res.data)

      if (res.data) {
        this.accountList.push({
          chain: 'Ethereum',
          address: this.rss3Profile.address
        })
        if (res.data.rss3File.accounts) {
          res.data.rss3File.accounts.forEach(account => {
            this.accountList.push({
              chain: account.platform,
              address: account.identity
            })
          })
        }
        res.data.assets.ethereum.forEach(item => {
          this.nftList.push(...item.nft)
        })
      }

      if (RSS3.isValidRSS3()) {
        // Check is following
        this.isFollowing = await this.checkIsFollowing()
      }
    } else {
      this.isMinePage = true

      this.rss3 = await RSS3.get()
      this.rss3Profile.address = this.rss3.account.address
      const profile = await this.rss3.profile.get()

      this.rss3Profile.avatar = profile?.avatar?.[0] || ''
      this.rss3Profile.username = profile?.name || ''
      this.rss3Profile.bio = profile?.bio || ''

      this.rss3Relations.followers = await this.rss3?.backlinks.get(this.rss3.account.address, 'following')
      this.rss3Relations.followings = (await this.rss3?.links.get(this.rss3.account.address, 'following'))?.list || []

      const res = await axios.get(`https://rss3-asset-hub-g886a.ondigitalocean.app/asset-profile/${this.rss3.account.address}`)
      console.log(res.data)

      if (res.data) {
        this.accountList.push({
          chain: 'Ethereum',
          address: this.rss3Profile.address
        })
        if (res.data.rss3File.accounts) {
          res.data.rss3File.accounts.forEach(account => {
            this.accountList.push({
              chain: account.platform,
              address: account.identity
            })
          })
        }
        res.data.assets.ethereum.forEach(item => {
          this.nftList.push(...item.nft)
        })
      }

      if (this.isMinePage) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
          this.isShowingAddAccount = true
        }
      }
    }
  },
  methods: {
    async toEditProfile () {
      await this.$router.push('/settings')
    },
    async checkIsFollowing () {
      const followList = await this.rss3.links.get(this.rss3.account.address, 'following')
      console.log(followList)
      if (typeof followList === 'undefined') {
        // No following list. Not following
        return false
      } else if (followList.list?.includes(this.$route.params.address)) {
        return true
      }
    },
    async follow () {
      this.rss3 = await RSS3.get()
      if (await this.checkIsFollowing()) {
        this.isFollowing = true
      } else {
        await this.rss3?.link.post('following', this.$route.params.address)
        this.isShowingSignNotice = true
      }
    },
    async unfollow () {
      this.rss3 = await RSS3.get()
      if (!(await this.checkIsFollowing())) {
        this.isFollowing = false
      } else {
        await this.rss3?.link.delete('following', this.$route.params.address)
        this.isShowingSignNotice = true
      }
    },
    toAccountsPage () {
      this.$router.push(`/${this.rss3Profile.address}/accounts`)
    },
    toManageAccount () {
      this.$router.push('/manage/account')
    },
    toNFTsPage () {
      this.$router.push(`/${this.rss3Profile.address}/nfts`)
    },
    cancelSign () {
      this.$gtag.event('cancelSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      this.isShowingSignNotice = false
    },
    async callSign () {
      this.$gtag.event('callSign', { userid: this.rss3.account.address, event_label: this.rss3.account.address })
      await this.rss3.files.sync()
      this.isShowingSignNotice = false
      this.isFollowing = !this.isFollowing
      await this.loadFollows()
    },
    async loadFollows () {
      this.rss3Relations.followers = await this.rss3.backlinks.get(this.$route.params.address, 'following')
      this.rss3Relations.followings = (await this.rss3.links.get(this.$route.params.address, 'following'))?.list || []
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      isMinePage: false,
      isFollowing: false,
      isShowingSignNotice: false,
      rss3: null,
      rss3Profile: {
        avatar: '',
        username: '',
        address: '',
        bio: ''
      },
      rss3Relations: {
        followers: [],
        followings: []
      },
      accountList: [],
      nftList: [],
      isShowingAddAccount: false
    }
  }
}
</script>

<style lang="less" scoped>
.main {

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem 1rem;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // align-items: center;
  // gap: 16px;
  .button-container {
    width: 100%;
  }
  .vs-button {
    width: 100%;
    margin-left:0;
  }

  .button-text {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 16px;
  }

  .card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .tips {
      text-align: center;
      color: #178BFF;
      font-weight: 100;
    }

    .vs-avatar {
      border: 4px solid #fff;
    }
  }

  .button-accounts-edit {
    background-color: @color-green;
  }

  .nftitem {
    object-fit: cover;
  }

}
</style>
