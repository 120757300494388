<template>
<div class="card" :style="style">
  <div class="card-header">
    <div class="card-title-wrapper">
      <span
        class="card-title"
        :class="{
          'green-title': color==='green' || titleColor === 'green',
          'purple-title': color==='purple' || titleColor === 'purple',
          'blue-title': color==='blue' || titleColor === 'blue'
        }"
      >
        {{$props.title}}
      </span>
      <slot name="accessibility" />
    </div>
    <slot name="button" />
  </div>
  <slot name="content" />
  <div
    class="card-footer"
    :class="{
      'green': color==='green',
      'purple': color==='purple',
      'blue': color==='blue'
    }"
  >
    <div class="footer-placeholder" />
    <div class="footer-button">
      <slot name="footer-button" />
    </div>
  </div>
</div>
</template>

<script>
export default {

  name: 'Card',

  props: {
    color: String,
    titleColor: String,
    width: String,
    height: String,
    title: String
  },
  computed: {
    style () {
      return {
        width: this.width,
        height: this.height,
        background: this.color === 'green' ? '#F6FFF5' : this.color === 'purple' ? '#F7F5FF' : this.color === 'blue' ? '#F5FAFF' : '#F4F7F8'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card{
    box-sizing: border-box;
    min-height: 170px;
    border-radius: 20px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * + * { margin-top: 20px;}
    .card-header{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-title-wrapper{
        .card-title {
          margin-right: 4px;
        }
        .vs-tooltip-content {
          display: inline-block;
        }
        .green-title {
          color: @title-green;
        }
        .purple-title {
          color: @title-purple;
        }
        .blue-title {
          color: @title-blue;
        }
      }
    }
  .card-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
    margin-top: auto;

    > * {
      display: flex;
    }

    .footer-tips {
      text-align: center;
      font-weight: 100;
      > * {
        margin-top: auto;
      }
    }

    &.green {
      color: @title-green;
      .footer-button > .vs-button {
        background-color: @color-green;
      }
    }
    &.purple  {
      color: @title-purple;
      .footer-button > .vs-button {
        background-color: @color-purple;
      }
    }
    &.blue  {
      color: @title-blue;
      .footer-button > .vs-button {
        background-color: @color-blue;
      }
    }
  }
}

</style>
