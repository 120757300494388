import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3 from 'web3'
import RSS3 from 'rss3-next'

let rss3
let web3

async function connectWallet () {
  const provider = new WalletConnectProvider({
    infuraId: '76af1228cdf345d2bff6a9c0f35112e1'
  })

  //  Enable session (triggers QR Code modal)
  await provider.enable()

  // const connectedWallet = provider.walletMeta

  //  Create Web3 instance
  web3 = new Web3(provider)

  // Subscribe to session disconnection
  provider.on('disconnect', (code, reason) => {
    console.log(code, reason)
    rss3 = null
  })

  const address = (await web3.eth.getAccounts())[0]

  console.log(address)

  rss3 = new RSS3({
    endpoint: 'https://rss3-asset-hub-g886a.ondigitalocean.app',
    address: address,
    sign: async (data) => await web3.eth.personal.sign(data, address, '')
  })

  return rss3
}

async function visitor () {
  if (rss3) {
    return rss3
  } else {
    return new RSS3({
      endpoint: 'https://rss3-asset-hub-g886a.ondigitalocean.app'
    })
  }
}

export default {
  connect: connectWallet,
  visitor: visitor,
  isValidRSS3: () => {
    return !!rss3
  },
  get: async () => {
    if (!rss3) {
      await connectWallet()
    }
    return rss3
  },
  attachToAddress: async (network, mainAddr) => {
    const address = {
      platform: network || 'Ethereum',
      identity: mainAddr
    }
    address.signature = await web3.eth.personal.sign(
      rss3.accounts.getSigMessage(address),
      mainAddr,
      ''
    )
    await rss3.accounts.post(address)
    await rss3.files.sync()
  }
}
