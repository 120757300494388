import Vue from 'vue'
import './styles/index.less'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' // Vuesax styles
import 'boxicons/css/boxicons.min.css' // boxicons

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import VueGtag from 'vue-gtag'
import Hotjar from 'vue-hotjar'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'G-QEMQGX7CE4' },
  pageTrackerScreenviewEnabled: true
}, router)
Vue.use(Hotjar, {
  id: '2541834' // Hotjar Site ID
})
Vue.use(Vuesax, {
  // options here
})

Sentry.init({
  Vue,
  dsn: 'https://31159cf40b484d8d8a960c1d562ec57a@o947126.ingest.sentry.io/5896371',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['rss3.page']
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
  logErrors: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
