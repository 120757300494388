<template>
    <input
        type="text"
        class="input"
        :placeholder="placeholder"
        :style="style"
        :value="value"
        @input="$emit('input', $event.target.value)"
    />

</template>

<script>

export default {
  name: 'Input',
  props: {
    placeholder: String,
    value: String,
    width: String
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    style () {
      return `width: ${this.width}`
    }
  }
}
</script>

<style scoped lang="less">
  input {
    box-sizing: border-box;
    font-family: SFCompactRounded;
    font-weight: 100;
    background: rgb(244,247,248);
    color: rgba(44,62,80,1);
    padding: 12px 20px;
    border-radius: 12px;
    border: none;
    resize: none;
    outline: none;
    font-size: 14px;
    :focus {
      background: rgb(240,243,244);
      padding-left: 15px;
    }

  }

  ::placeholder {
    color: rgba(44,62,80,0.4);
    font-weight: 500;
  }
</style>
