<template>
<div v-on:click="toFollowers" class="follower-container button-shadow-secondary">
<vs-avatar size="70" circle><img :src="avatar"/></vs-avatar>
<div class="info">
    <span class="username">{{$props.username}}</span>
    <span class="address">{{$props.address.slice(0,6)}}...{{$props.address.slice(-4)}}</span></div>
</div>
</template>

<script>
export default {

  name: 'FollowerCard',

  props: {
    avatar: String,
    username: String,
    address: String
  },
  methods: {
    toFollowers () {
      this.$gtag.event('visitOther', { event_label: this.$props.address })
      this.$router.push(`/${this.$props.address}`)
    }
  }
}
</script>

<style lang="less" scoped>
.follower-container{
  font-family: SFCompactRounded;
  height: 100px;
  width: 100%;
  padding: 15px 36px 15px 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 42px;
  border-radius: 20px;
  background-color:#FFFFFF;
  .info {
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  .username {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .address {
    font-weight: 500;
    font-size: 1.125rem;
  }
  }
}
</style>
