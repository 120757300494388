<template>
  <div class="profile-container">
    <vs-avatar circle size="100">
    <img :src="avatar"/>
    </vs-avatar>
    <span class="username">{{$props.username}}</span>
    <span class="address">{{$props.address.slice(0,6)}}...{{$props.address.slice(-4)}}</span>
    <div class="relations">
      <a @click="toFollowers"><div class="followers">{{$props.followers.length}} followers</div></a>
      <a @click="toFollowings"><div class="followings">{{$props.followings.length}} followings</div></a>
    </div>
    <div class="bio">{{$props.bio}}</div>
  </div>
</template>

<script>
export default {
  name: 'Profile',

  props: {
    avatar: String,
    username: String,
    address: String,
    followers: Array,
    followings: Array,
    bio: String
  },

  methods: {
    toFollowers () {
      this.$router.push(`/${this.$props.address}/followers`)
    },
    toFollowings () {
      this.$router.push(`/${this.$props.address}/followings`)
    }
  }
}
</script>

<style lang="less" scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // gap: 16px;
  > * + * { margin-top: 1rem;}
  font-family: SFCompactRounded;
  .username {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .address {
    font-weight: 500;
    font-size: 1.125rem;
  }
  .relations{
    font-weight: 500;
    font-size: 1.125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // gap: 32px;
    > * + * { margin-left: 2rem;}
    a, a:active, a:visited {
      text-decoration: none;
      color: #0072FF;
    }
  }
  .bio {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
</style>
