<template>
<div class="trait-container">
<div class="trait-type">{{$props.traitType}}</div>
<div class="trait-value">{{$props.traitValue}}</div>
</div>
</template>

<script>
export default {

  name: 'Trait',

  props: {
    traitType: String,
    traitValue: String
  }
}
</script>

<style lang="less" scoped>
.trait-container{
  font-family: SFCompactRounded;
  min-width: fit-content;
  padding: 12px 20px 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  background-color:#F7F5FF;
  border-radius: 12px;
  .trait-type {
    text-transform: uppercase;
    color: #7553FF;
    font-size: 12px;
  }
  .trait-value {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    color: #000;
  }
}
</style>
